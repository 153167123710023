import React from 'react'

//=======IMAGES=======
import devPic from '../../img/devPic.1.jpg'

//=======STYLING======
import './aboutMeComponents.css'

export default function Backend() {
    return (
        <div className='row'>
            <div className='col-12 col-md-4 picCol'>
                <img alt='' src={devPic} className='aboutMePic' />
            </div>
            <div className='col-12 col-md-8 aboutMeText'>
                <div className='aboutMeDesc'>
                    <h2 style={{fontSize:'5vw'}} className='display-3'>Back-end <span style={{color:'white'}}> Development</span></h2>
                    <p>
                        Most of my work has been with the MERN stack. While working with Node.js and Express are my cup of tea I do have experience with Ruby. Coming around to
                        databases I have worked mostly with non-relation databases but do have experience with relational ones as well. If you are interested in working with some 
                        newer technologies I am always willing to learn! 
                    </p>
                </div>
            </div>
        </div>
    )
}



