import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, } from '@fortawesome/free-solid-svg-icons';

import '../../css/app.css'

export default function ContactPanel() {
    return (
            <div className='img-wrap'>
                <Link 
                    style={{textDecoration: 'none'}}
                    to='/contactme'
                >
                    <div 
                        className='homepage-card'
                        style={{backgroundImage: "url('https://images.unsplash.com/uploads/1413222992504f1b734a6/1928e537?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')"}}
                    >   
                        <div className='img-desc'><h4><FontAwesomeIcon icon={faMobileAlt} /> Contact Me</h4></div>
                    </div>
                    
                </Link>
            </div>
    )
}
