import React from 'react';
import emailjs from 'emailjs-com';

import { Spring } from 'react-spring/renderprops'

//FORM STYLING
const formControl = {
    margin: '10px 0'
}

class EmailForm extends React.Component{
    constructor(props){
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            name: '',
            email: '',
            msg: '',
            done: null,
        }
    }

    onChange = e => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }


    onSubmit(e){
        e.preventDefault();

        const message = {
            message: this.state.msg,
            from_name: this.state.name, 
            reply_to: this.state.email
        }

        emailjs.send(
            'cltn.dev',
            'portfolio_email',  
            message, 
            'user_UNeCsNQSF3rmTJDKYt8I2'
            )
            .then(res => {
                this.setState({
                    // name: '',
                    email: '',
                    msg: '',
                    done: true
                }) 
                console.log(res)})
            .catch(err => console.log(err));

        
    }

    render(){
        return(
            <div>
                {this.state.done ? ( 
                    <div> 
                        <h1 style={{textAlign:'center'}}>Success! I am glad that reached out, I will get in touch with you as soon as possible. Have a good day {this.state.name}</h1>
                    </div>
                    
                ): 
                (
                    <form onSubmit={this.onSubmit}className='form-group' >

                        <Spring 
                            from={{marginLeft:-200,
                                    paddingBottom: 200,
                                    opacity: 0}}
                            to={{marginLeft:0,
                                paddingBottom: 0,
                                opacity: 1}}
                            config={{delay: 100 }}
                        >
                            {props => (
                                <div style={props}>
                                    <h1 className='display-4'>Contact Form</h1>
                                </div>
                            )}
                        </Spring>
                        
                        <Spring
                        from={{opacity:0,
                                paddingBottom: 200}}
                        to={{opacity:1,
                        paddingBottom:0}}
                        config={{delay:100}}>
                            {props => (
                                
                                <div style={props}>
                                    <label>Name:</label>
                                    <input 
                                        style={formControl}
                                        type='text'
                                        id='name'
                                        className='form-control'
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        placeholder='Name'
                                        required
                                    />
                                </div>
                            )}
                        </Spring>
                        
                        <Spring
                        from={{opacity:0,
                                paddingBottom: 200}}
                        to={{opacity:1,
                        paddingBottom:0}}
                        config={{delay:200}}>
                            {props => (
                            <div style={props}>
                                <label>Email:</label>
                                <input 
                                    style={formControl}
                                    type='email'
                                    className='form-control'
                                    id='email'
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    placeholder='Email'
                                    required
                                />
                            </div> 
                        )}
                        </Spring>
                        <Spring
                        from={{opacity:0,
                        paddingBottom: 200}}
                        to={{opacity:1,
                        paddingBottom:0}}
                        config={{delay:200}}>
                            {props => (
                            <div style={props}>
                                <label>Message:</label>
                                <textarea
                                    style={formControl}
                                    className='form-control'
                                    id='msg'
                                    name='msg'
                                    onChange={this.onChange}
                                    value={this.state.msg}
                                    placeholder='Message'
                                    required
                                />
                            </div>
                        )}
                        </Spring>
                        
                        <input 
                            style={formControl}
                            type='submit' 
                            value='Submit'
                            className='form-control btn btn-primary'
                        />

                    </form>
                )}
            </div>
        )
    }
}

export default EmailForm;