import React from 'react'
import { Spring } from 'react-spring/renderprops'
import { faBrain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Styling
const cardIcon = {
    color : '#de6262', 
    fontSize: '3rem' 
}
const ulStyle = {
    listStyle: 'none',
    marginLeft: '0',
    marginTop: '0',
    paddingLeft: '0'
}
const cardDescHeader = {
    color:'#de6262',
    fontSize: '18px'
}

export default function It() {
    return (
        <div className='col-md-4'>
            <Spring
             from={{opacity:0, marginTop: 500 }}
             to={{opacity:1, marginTop: 0}}
             config={{ tension:180, friction:12, precision:0.1, delay: 300}}>
             {props => (
                 <div style={props}>
                    <div className='card'>
                        <div className='card-header'>
                            <FontAwesomeIcon icon={ faBrain } style={cardIcon} />
                            <h5 className='card-title'>Other Nifty Knowledge</h5>
                            <h6 className='card-title'>Hardware</h6>
                        </div>
                        <div className='card-body'>
                            <p className="card-text">Building computers was something that I developed a passion for growing up. It lead me to setting up servers both on the cloud and locally</p>
                            <hr />
                            <h6 style={cardDescHeader}>Experience:</h6>
                            <ul style={ulStyle}>
                                <li>Windows</li>
                                <li>Linux</li>
                                <li>MacOS</li>
                                <li>Installing Operating Systems</li>
                                <li>Trouble Hardware/Software issues</li>
                            </ul>
                        </div>
                    </div>
                </div> 
                )}
            </Spring>
        </div>  
    )
}
