import React from 'react'

//REACT-SPRING
import { Spring } from 'react-spring/renderprops';

//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'

const projectsHeader = {
    width: '100%',
    height: '15vw',
    background: 'linear-gradient(to bottom, #de6262 0%, #ffb88c 100%)',
    overflow: 'hidden'
}
const headerText = {
    display:'inline-block',
    position:'relative',
     top:'25%',
     fontSize: '10vw',
     color: '#fff'
}

export default function ContactHeader() {
    return (
        <div style={projectsHeader}>
            <div style={headerText}>
                <Spring
                    from={{ 
                    opacity: 0,
                    marginLeft:-200,
                    }}
                    to={{
                    opacity: 1,
                    marginLeft: 0,
                }}
                >
                    {props => (
                        <div style={props}>
                            <h1 className='display-1' style={{fontSize:'8vw'}}><FontAwesomeIcon icon={faMobileAlt} /> Contact</h1>
                        </div>
                    )}
                </Spring>
            </div>
        </div>
    )
}
