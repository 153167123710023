import React from 'react'

//========IMAGES=======//
import designPic from '../../img/designPic.1.jpg';

//========STYLING======//
import './aboutMeComponents.css'


export default function Design() {
    return (
        <div className='row'>
            <div className='col-12 col-md-8 aboutMeText'>
                <div className='aboutMeDesc'>
                    <h2 style={{fontSize:'5vw'}} className='display-3'>The <span style={{color:'white'}}>Design</span> process</h2>
                    <p>
                        I typically will start by getting a clear vision of what the client desires and design a mock up in Photoshop. After approval from the client, I like to use tools
                        like Bootstrap/Bluma/SemanticUi to create the best user interface I can. I also love creating things with React.js and React transitions. This portfolio was written 
                        with Bootstrap, react.js, and react transitions! Didn't see the technology that you are looking to work with? Well the beauty of web-development is that I am always 
                        learning new tech. Go ahead and reach out to me and we will see what amazing things we can create together! 
                    </p>
                </div>
            </div>
            <div className='col-12 col-md-4 picCol'>
                <img alt='' className='aboutMePic' src={designPic} />
            </div>
        </div>
    )
}

