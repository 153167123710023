import React from 'react'
import '../css/ribbon.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Spring, animated} from 'react-spring/renderprops'

class Ribbon extends React.Component {
    render(){
        return (
            <Spring
                    native
                    from={{opacity:0, marginTop: -50, marginRight: -50}}
                    to={{opacity:1, marginTop: 0, marginRight: 0}}
                    config={{tension:120, friction:15}}
                >
                    {props => (
                        <animated.div style={props} className='ribbon'>
                            <div>
                                <h6><FontAwesomeIcon icon={this.props.ribbonIcon} />{this.props.ribbonText}</h6>
                            </div>
                        </animated.div>
                    )}
            </Spring>
        )
    }
}

export default Ribbon;
