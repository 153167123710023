import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons'

export default function AboutMePanel() {
    return (
                    <div class='img-wrap'>
                        <Link 
                            style={{textDecoration: 'none'}}
                            to ='/aboutme'
                        >
                            <div 
                                class='homepage-card'
                                style={{backgroundImage:"url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')"}}
                            >
                                <div class='img-desc'><h4><FontAwesomeIcon icon={faUserAstronaut} /> About Me</h4></div>
                            </div>
                            
                        </Link>
                    </div>
    )
}
