import React, { Component } from 'react'

//=======FONT-AWESOME=====//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//=======SPRING-TRANSITIONS=======//
import { Spring } from 'react-spring/renderprops'


export default class Header extends Component {
    render() {
        return (
            <div>
                <div style={projectsHeader}>
                    <Spring
                        from={{marginTop:-500}}
                        to={{marginTop: 0}}>
                        {props => (
                            <div style={props}>
                                <FontAwesomeIcon  icon={this.props.icon} style={headerIcon} />
                                <div style={headerText}>  
                                    <h1 style={{fontSize:'10vw'}}className='display-1'>{this.props.text}</h1>
                                </div>
                            </div>
                        )}
                    </Spring>
                </div>
            </div>
        )
    }
}

//======= ABOUT ME HEADER =======//
const projectsHeader = {
    // width: '100%',
    height: '15vw',
    background: 'linear-gradient(to bottom, #de6262 0%, #ffb88c 100%)',
    overflow: 'hidden'
}
const headerText = {
    display:'inline-block', 
    position:'relative',
     top:'25%',
     fontSize: '10vw',
     color: '#fff'
}
const headerIcon = {
    fontSize: '10vw',
    color: '#fff',
    display: 'inlineBlock',
    position: 'relative',
    top: '15%'
}
