import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/app.css'


//Importing Components 
import HomePage from './components/Homepage'
import Projects from './components/projects.component'
import AboutMe from './components/aboutMe.component'
import ContactMe from './components/contactMe.component'


function App() {
  return (
    <div className="App">

      <Router>
        <Route path='/' exact component={HomePage } />
        <Route path='/projects' component={Projects} />
        <Route path='/aboutme' component={AboutMe} />
        <Route path='/contactme' component={ContactMe} />
      </Router>
    </div>
  );
}

export default App;
