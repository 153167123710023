import React from 'react'

//REACT-SPRING
import { Spring } from 'react-spring/renderprops'

export default function ContactInfo() {
    return (
        <div>
            <Spring
                from={{opacity:0,
                marginRight: -1000}}
                to={{opacity:1,
                marginRight: 0}}
            >
                {props => (
                    <div>
                        <h4  style={props} className='display-4'>Information</h4>
                    </div>
                )}
            </Spring>
            
            <Spring
            from={{opacity:0,
            marginRight: -1000}}
            to={{opacity:1,
            marginRight: 0}}
            config={{delay:100}}>
            {props => ( 
                <p style={props}><b>Email:</b> cltnnielsen@gmail.com</p>
            )}
            </Spring>

            <Spring
            from={{opacity:0,
            marginRight: -1000}}
            to={{opacity:1,
            marginRight: 0}}
            config={{delay:200}}>
            {props => ( 
                <p style={props}><b>Number:</b> (385)-630-5807</p>
            )}
            </Spring>

            <Spring
            from={{opacity:0,
            marginRight: -1000}}
            to={{opacity:1,
            marginRight: 0}}
            config={{delay:300}}>
            {props => ( 
                <p style={props}><b>Facebook:</b> Colton Nielsen</p>
            )}
            </Spring>

            <Spring
            from={{opacity:0,
            marginRight: -1000}}
            to={{opacity:1,
            marginRight: 0}}
            config={{delay:400}}>
            {props => ( 
                <p style={props}><b>Github:</b> thenewlebowski</p>
            )}
            </Spring>
        </div>
        
    )
}
