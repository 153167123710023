//===================REACT===================
import React from 'react';

//=====================COMPONENTS===================
import Navbar from './navbar.component';
import Header from './projects/header';
import Frontend from './projects/frontend';
import Backend from './projects/backend.js';
import Banner from './banner';
import It from './projects/it';

//=====================ANIMATIONS===================
import { Spring } from 'react-spring/renderprops'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import { faReact} from '@fortawesome/free-brands-svg-icons'

//=====================IMAGES=====================
import PortfolioScreenshot from '../img/portfolioScreenshot.png'
import PlumbersstockScreenshot from '../img/plumbersstock.png'
import SproutScreenShot from '../img/sproutScreenshot.png'
import GoGrelloScreenshot from '../img/gogrello.png'

//STYLING FOR COMPONENTS
const rowStyle = {
    zIndex: '1',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    marginTop: '5vh'

}
const headerText = {
    display:'inline-block', 
    position:'relative',
     top:'15%',
     fontSize: '125%',
     color: '#fff'
}

const headerIcon = {
    fontSize: '10vw',
    color: '#fff',
    display: 'inlineBlock',
    position: 'relative',
    top: '15%'
}

//RECENT WORK
const recentWorkHeader = {
    width: '100%',
    height: '15vw',
    background: 'linear-gradient(to bottom, #ffb88c 0%, #de6262 100%)',
    overflow: 'hidden'
}
class Projects extends React.Component {
    render(){
        return(
            <div style={{backgroundColor:'#ffb88c'}}>
                <Navbar />
                
                <Banner ribbonText={'   Made with Animations from react-spring'} ribbonIcon={faReact}/>
                {/*Header Animation W/ Spring*/}
                <Spring
                    from={{opacity:0, marginLeft: -1000}}
                    to={{opacity:1, marginLeft: 0}}>
                    {props => (
                        <div style={props}><Header /></div>   
                    )}
                </Spring>
                
                <div className='container-fluid'>
                    <div className='row' style={rowStyle}>
                        <Frontend/>
                        <Backend />
                        <It />
                    </div>
                </div>

                <div style={{marginTop: '5vh'}}>
                    <div style={recentWorkHeader}>
                        <FontAwesomeIcon icon={faFlask} style={headerIcon} />
                        <div style={headerText}>  
                            <h1 style={{fontSize:'10vw'}} className='display-1'>Recent Work</h1>
                        </div>
                    </div>
                </div>

                <div className='grid' style={{backgroundColor: 'rgb(222, 98, 98)'}}>
                    <div style={{margin: '10px'}}className='img-wrap'>
                        <div className='homepage-card'
                            style={{backgroundImage: `url('${GoGrelloScreenshot}')`}}>                                
                            <div className='img-desc'><h4><FontAwesomeIcon icon={faUserAstronaut}/>   Portfolio </h4><h5>A static website I wrote using React, React-Spring, Bootstrap 4, and Font-Awesome Icons. I had a ton of fun with this project and learned a ton!</h5> </div>
                        </div>
                    </div>
                    <div style={{margin: '10px'}}className='img-wrap'>
                        <div className='homepage-card'
                            style={{backgroundImage: `url('${PlumbersstockScreenshot}')`}}>                                
                            <div className='img-desc'><h4><FontAwesomeIcon icon={faUserAstronaut}/>   Portfolio </h4><h5>A static website I wrote using React, React-Spring, Bootstrap 4, and Font-Awesome Icons. I had a ton of fun with this project and learned a ton!</h5> </div>
                        </div>
                    </div>
                    <div style={{margin: '10px'}}className='img-wrap'>
                        <div className='homepage-card'
                            style={{backgroundImage: `url('${PortfolioScreenshot}')`}}>                                
                            <div className='img-desc'><h4><FontAwesomeIcon icon={faUserAstronaut}/>   Portfolio </h4><h5>A static website I wrote using React, React-Spring, Bootstrap 4, and Font-Awesome Icons. I had a ton of fun with this project and learned a ton!</h5> </div>
                        </div>
                    </div>
                    <div style={{margin: '10px'}} className='img-wrap'>
                        <div 
                            style={{backgroundImage: `url('${SproutScreenShot}')`}}
                            className='homepage-card'
                        >
                            <div className='img-desc'><h4>Sprout</h4><h5>A dynamic web app that I created with the MERN stack. It's purpose is to help start up companies get their name out to the local community.</h5></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Projects