import React, { useState } from 'react'
import { Spring } from 'react-spring/renderprops'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faToiletPaper } from '@fortawesome/free-solid-svg-icons'

//Styling
const cardIcon = {
    color : '#de6262', 
    fontSize: '3rem' 
}
const ulStyle = {
    listStyle: 'none',
    marginLeft: '0',
    marginTop: '0',
    paddingLeft: '0'
}
const cardDescHeader = {
    color:'#de6262',
    fontSize: '18px'
}
export default function Frontend() {
    const [list, setList] = useState([
        'Semantic UI',
        'Bootstrap',
        'Core UI',
        'Github',
        'React',
        'Vue',
        'Git',
    ]);

    const list2 = [
        'Javascript',
        'Vuetify',
        'HTML',
        'Sass',
        'CSS',
        'JSX',
    ];

    const head = {
        title    : 'Front-end',
        subTitle : 'Client side',
        desc     : 'Web-development can be a daunting task and can overwhelm an individual if they are building from scratch. Here are a few list I like to use to speed up the process and create an easier to work with slate for the future.'
    };

    return (
            <div className='col-md-4'>
                 <Spring
                        from={{opacity:0, marginTop: 500 }}
                        to={{opacity:1, marginTop: 0}}
                        config={{ tension:180, friction:12, precision:0.1, delay:100 }}>
                        {props => (
                            <div style={props}>
                                <div className='card'>
                                    <div className='card-header'>
                                        <FontAwesomeIcon icon={ faDesktop } style={cardIcon} />
                                        <h5 className='card-title'>{head.title}</h5>
                                        <h6 className='card-title'>{head.subTitle}</h6>
                                    </div>
                                    <div className='card-body'>
                                        <p className="card-text">{head.desc}</p>
                                        <hr />
                                        <h6 style={cardDescHeader}>Dev Tools:</h6>
                                        <ul style={ulStyle}>
                                            {list.map( li => <li>{li}</li> )}
                                        </ul>
                                        <h6 style={cardDescHeader}>Lingo:</h6>
                                        <ul style={ulStyle}>
                                            {list2.map( li => <li>{li}</li> )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                             )}
                </Spring>
            </div>

    )
}
