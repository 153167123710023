import React from 'react';

//=========COMPONENTS========//
import Navbar from './navbar.component'
import EmailForm from './contactComponets/emailForm.component'
import ContactInfo from './contactComponets/contactInfo'
import ContactHeader from'./contactComponets/contactHeader';
import Ribbon from './banner'

//=========FONT-AWESOME========//
import { faEnvelope,} from '@fortawesome/free-solid-svg-icons'

class Contact extends React.Component {
    render(){
        return(
            <div style={{backgroundColor:'#ffb88c', height: '100vh', overflow: 'hidden'}}> 
                <Navbar />
                <Ribbon ribbonText={'   Contact Form that works with Emailjs'} ribbonIcon={faEnvelope} />
                <ContactHeader />                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 col-lg-8'>
                                <EmailForm />
                        </div>
                        <div className='col-12 col-lg-4' style={{textAlign:'center'}}>
                            <ContactInfo />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact