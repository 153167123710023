import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons'

const projectsHeader = {
    width: '100%',
    height: '15vw',
    background: 'linear-gradient(to bottom, #de6262 0%, #ffb88c 100%)',
    overflow: 'hidden'
}
const headerIcon = {
    fontSize: '10vw',
    color: '#fff',
    display: 'inlineBlock',
    position: 'relative',
    top: '15%'
}

const headerText = {
    display:'inline-block', 
    position:'relative',
     top:'15%',
     fontSize: '125%',
     color: '#fff'
}

export default function Header() {
    return (
        <div>
            <div style={projectsHeader}>
                <FontAwesomeIcon icon={faCode} style={headerIcon} />
                <div style={headerText}>  
                    <h1 style={{fontSize:'10vw'}} className='display-1'>Experience</h1>
                </div>
            </div>
        </div>
    )
}
