import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';

export default function ProjectsPanel() {
    return (
            <div className='img-wrap'>
                <Link style={{textDecoration: 'none'}} to='/projects'>
                    <div 
                        className='homepage-card'
                        style={{backgroundImage:"url('https://images.unsplash.com/photo-1491336238524-c990bd671778?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')"}}
                    >
                        <div className='img-desc'><h4><FontAwesomeIcon icon={faDiceD20} /> Projects</h4></div>
                    </div>
                </Link>
            </div>
    )
}
