import React from 'react'

//REACT-ROUTER-DOM
import { Link } from 'react-router-dom';

//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default function GithubPanel() {
    return (
                        <div className='img-wrap'>
                            <a style={{textDecoration: 'none'}} href='https://github.com/thenewlebowski'>
                                <div className='homepage-card'
                                    style={{backgroundImage: "url('https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')"}}    
                                >
                                    <div className='img-desc'><h4><FontAwesomeIcon icon={ faGithub }/> Github</h4></div>
                                </div> 
                            </a>
                        </div>
    )
}
