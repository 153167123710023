import React from 'react'

//=======IMAGES======//
import profilePic2 from '../../img/profilePic2.1.jpg';

//=======STYILING=====//
import './aboutMeComponents.css'

//=======REACT-SPRING=====//
import { Spring } from 'react-spring/renderprops'

export default function Me() {
    return (
        <div className='row'>
            <div className='col-12 col-md-4 picCol'>
                <Spring 
                    from={{width:'0%'}}
                    to={{width:'50%'}}>
                        {props =>(
                            <img alt='' className='aboutMePic' style={props} src={profilePic2}/>
                        )}
                </Spring>
                
            </div>
            <div className='col-12 col-md-8 aboutMeText'>
                <div className='aboutMeDesc'>
                    <h2 style={{fontSize:'5vw'}} className='display-3'>Hi I'm <span style={{color:'white'}}>Colton,</span></h2>
                    <p>
                    When I was about 13 years old I took apart my broken Xbox 360 controller. I couldn't afford to buy a new one and so I attemped to fix. I, with the power
                        of fourms and youtube videos, was able to soder a new capacitor on and get it to work again. While I was at it I sprayed a design from Halo 3 on it and 
                        changed the typical buttons to 9mm bullet casings. This was the begining of my interest in finding out how things tick and an eye for design.
                    </p>
                </div>
            </div>
        </div>
    )
}