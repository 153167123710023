import React, { useState } from 'react'
import { Spring, } from 'react-spring/renderprops'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch, } from '@fortawesome/free-solid-svg-icons'

//Styling
const cardIcon = {
    color : '#de6262', 
    fontSize: '3rem' 
}
const ulStyle = {
    listStyle: 'none',
    marginLeft: '0',
    marginTop: '0',
    paddingLeft: '0'
}
const cardDescHeader = {
    color:'#de6262',
    fontSize: '18px'
}

export default function Backend() {

    const head = {
        title : 'Back-end',
        text : 'Backend is my specialty, almost like the sith is Obi-wan\'s speciality. I like to optimize and create a proficient backend with micro-services. Instead of making one giant backend I believe having multiple services preforming specific tasks can help future developers create new features for the platform in a timely manner.', 
        subText :( <em>Here are a few tools I like to use: </em> )
    };

    const list = [
        'Javascript (Node.js)',
        'Java',
        'PHP',
        'C++',
        (<h6 style={cardDescHeader}>Back-end Building Blocks:</h6>),
        'Mongoose',
        'MongoDB',
        'Laravel',
        'Express',
        'Node.js',
        'mySQL',
        (<h6 style={cardDescHeader}>Dev-Ops:</h6>),
        'Docker',
        'Kubernetes'
    ];
    
    return (
        <div className='col-md-4'>
            <Spring
             from={{opacity:0, marginTop: 500 }}
             to={{opacity:1, marginTop: 0}}
             config={{ tension:180, friction:12, precision:0.1, delay: 200}}>
             {props => (
                 <div style={props}>
                     <div className='card'>
                        <div className='card-header'>
                            <FontAwesomeIcon icon={ faCodeBranch } style={cardIcon} />
                            <h6 className='card-title'>Back-end</h6>
                            <h6 className='card-title'>Server side</h6>
                        </div>
                        <div className='card-body'>
                            <p className="card-text">{head.text}</p>
                            <p className="card-text">{head.subText}</p>
                            {/* <p className="card-text">Communicating with your front end team in order to develop everything they need in an API is a <b>must</b>. A solid back-end is the foundation of the project. If it isn't programmed well, in the long run the app will suffer </p> */}
                            <hr />
                            <h6 style={cardDescHeader}>Back-end language: </h6>
                            <ul style={ulStyle}>
                                {list.map(li => (<li>{ li }</li>))}
                            </ul>
                        </div>
                    </div>
                 </div>
             )}
            </Spring>
            
        </div>
    )
}
