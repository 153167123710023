//=========REACT=========//
import React from 'react';
import Navbar from './navbar.component';

//=======FONT-AWESOME=====//
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

//=======REACT-SPRING======//
import { Spring } from 'react-spring/renderprops'

//======COMPONENTS========//
import Ribbon from './banner';
import Header from './Header';
import Me from './aboutMeComponents/Me'
import Design from './aboutMeComponents/Design'
import Backend from './aboutMeComponents/Backend'

import './aboutMeComponents/aboutMeComponents.css'

class AboutMe extends React.Component {


    render(){
        return(
            <div>
                <Navbar />
                <Ribbon ribbonText={'  More about me and how I do things'} ribbonIcon={faUserCircle}/>
                <Header icon={faUserCircle} text={'About Me'}/>
                <div className='container-fluid'>
                    <Spring
                        from={{
                            marginTop:500,
                        }}
                        to={{
                            marginTop:0
                        }}
                    >
                        {props => (
                            <div style={props}>
                                < Me />
                            </div>
                        )}
                    </Spring>
                    
                    <Design />
                    <Backend />
                </div>
            </div>
        )
    }
}

export default AboutMe