import React from 'react';

//COMPONENTS
import ProjectsPanel from './homepageComponents/projectsPanel'
import AboutMePanel from './homepageComponents/aboutMePanel'
import ContactPanel from './homepageComponents/contactPanel'
import GithubPanel from './homepageComponents/githubPanel'

class HomePage extends React.Component {

    render(){
        return(
            <div className='grid'>
                <ProjectsPanel />
                <AboutMePanel />
                <ContactPanel />
                <GithubPanel />
            </div>
        )
    }
}

export default HomePage
